body{
	font-family: 'Roboto Condensed', sans-serif;
	height: 100%;
	width: 100%;
	background-color: whitesmoke;
	overflow-x: hidden;
	margin: 0;
}

//form
.form-style{
    width: 75%;
	max-width:450px;
	padding:30px;
	margin:40px auto;
	background: #FFF;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	display: flex;
    flex-direction: column;

	.checkbox-label{
		display: flex;
		align-items: center;
	}

	.inner-wrap{
		padding: 30px;
		background: #F8F8F8;
		border-radius: 6px;
		margin-bottom: 15px;
	}

	h1{
		background: #2A88AD;
		padding: 20px 30px 15px 30px;
		margin: -30px -30px 30px -30px;
		border-radius: 10px 10px 0 0;
		color: #fff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
		box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
		border: 1px solid #257C9E;
		font-weight: normal;
		width: 100%;
	}

	h1 > span{
		display: block;
		margin-top: 2px;
		font: 13px;
	}

	label{
		display: block;
		font: 13px;
		color: #888;
		margin-bottom: 15px;
	}

	.box-input {
		display: block;
		box-sizing: border-box;
		width: 100%;
		padding: 8px;
		border-radius: 6px;
		border: 2px solid #fff;
		box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
	}

	.section{
		color: #2A88AD;
		margin-bottom: 5px;

		span {
			background: #2A88AD;
			padding: 5px 10px 5px 10px;
			position: absolute;
			border-radius: 50%;
			border: 4px solid #fff;
			font-size: 14px;
			margin-left: -45px;
			color: #fff;
			margin-top: -3px;
		}
	}

	input:focus-visible, select:focus-visible, textarea:focus-visible {
		outline-color: darkgray;
	}	

	input[type="checkbox"]{
		height: 1.25em;
		width: 1.25em;
		margin-left: 10px;
	}	

	.month-field label, .day-field label{
    margin-bottom: 0;
	}

	input[type="button"]:hover, input[type="submit"]:hover, button:hover{
	background: #2A6881;
	box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.28);
	}

	input[type="button"], input[type="submit"], button{
		background: #2A88AD;
		padding: 8px 20px 8px 20px;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		color: #fff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
		font: normal 30px 'Bitter', serif;
		-moz-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
		-webkit-box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
		box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.17);
		border: 1px solid #257C9E;
		font-size: 15px;
		cursor: pointer;
	}

	#gender-label{
		margin-bottom: 5px;
	}

	.date-bundle{
		display: inline-flex;
		.label-input-field{
			max-width: 100px;
		}
	}

	.radio-group{
		display: inline-flex;
		align-items: baseline;
	
		.label-input-field{
			padding-right: 25px;
			label{
				margin-bottom: 0;
				display: flex;
				align-items: center;
			}
			.radio-box{
				margin-right: 10px;
				height: 1.25em;
				width: 1.25em;
	
			}
		}
	}	

	textarea{
		height: 100px;
	}

	.delete-btn{
		float: right;
	}

	#selected-list{
		margin-top: 0;
		list-style-type: none;
		padding-left: 10px;
		li{
			margin: 3px;
			display: flex;
			align-items: baseline;
			.search-name-text{
				margin: 0;
			}
			
			.subtext{
				color: #888;
				font-size: 12px;
				padding-left: 5px;
				margin: 0;
			}
			
			button{
				border-radius: 10px;
				border: thin solid darkgray;
				margin-right: 5px;
				cursor: pointer;
				background-color: lightgray;
				padding: 0 5px;
			}
			button:hover{
				background-color: rgb(196, 195, 195);
			}
		}
	}

	#selected-label{
		margin-bottom: 5px;
		margin-top: 15px;
	}

	select, input[type="checkbox"], input[type="radio"]{
		cursor: pointer;
	}

	input[type="file"]{
		margin-bottom: 15px;
		display: grid;
	}
}



.is-selected{
	background-color: lightsteelblue;
}

.loader {
	background-color: whitesmoke;
	height: fit-content;
	width: fit-content;
	position: absolute;
	border-radius: 30px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	left: 50%;
	top: 50%;
	margin-left: -126px;
	margin-top: -126px;
	.loader-bar{
	border: 16px solid grey; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 50px;
  }
  .form-style{
	  display: none;
  }
}

.loader + section{
	display: none;
}

.search-bar{
    width: 80%;
	max-width: 450px;
    padding: 8px;
    border-radius: 6px;
    border: 2px solid #fff;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 33%);
	margin-left: 10%;
}


.pdf-nav:disabled{
	display: none;
}






//nav

  #head-wrapper{
	display: flex;
	justify-content: center;
	height: fit-content;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1000;
  
	#head{
	  // border-bottom: thin black solid;
	  height: 125px;
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  background-color: rgb(229, 226, 226);
	  border-bottom: thin #d8d5d5 solid;
	  position: relative;
	  z-index: 1000;
  
  
	  #header-title-container{
		display: flex;
		flex-direction: column;
		color: black;
		padding-left: 30px;
	  }

	  .fa-bars{
		color: black;
		font-size: 2.5em;
		cursor: pointer;
		margin-right: 50px;
		display: none;
	  }

	  .fa-bars:hover{
		  color: gray;
	  }
  
	  #nav-items{
		display: inline-flex;
		padding-right: 50px;
		.nav-item{
		  white-space: nowrap;
		  margin: 0;
		  padding-right: 20px;
		  font-weight: normal;
		  cursor: pointer;
		  font-size: 1.5em;
		  text-decoration: none;
		  color: black;
		}
		.nav-item:hover{
		  color: gray;
		}
	  }
	  
	}
  }

  #page-body{
	  margin-top: 150px;
  }

//main page
#main-page{
    display: flex;
    flex-direction: column;
}


//search
.search-scroll{
	margin-top: 10px;
	overflow-y: scroll;
	max-height: 200px;
	height:fit-content ;
	.search-option{
		cursor: pointer;
		padding: 0.5em;
		margin: 0;
		.search-name-text{
			margin: 0;
		}
		
		.subtext{
			color: #888;
			font-size: 12px;
			padding-left: 15px;
			margin: 0;
		}
	}
	.search-option:hover{

		background-color: lightgray;
	}
}

//content card
#content-card-container, #person-card-container{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 50px;
	.content-card{
		border-radius: 10px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
		width: 220px;
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin: 10px;
		cursor: pointer;
	
		img, canvas{
			max-width: 200px;
			max-height: 200px;
		}
	
		p{
	
			margin: 0;
			margin-bottom: 15px;
			border-radius: 10px 10px 0 0;
			background-color: #2A88AD;
			width: 190px;
			padding: 15px;
			color: whitesmoke;
			height: 40px;
		 
		}
	}
}

.individual-content-page.form-style{
	max-width: fit-content;

	.pagination-display{
		display: flex;
		align-items: baseline;
		button{
			height: fit-content;
			margin: 5PX;
		}
	}
	img, canvas{
		max-width: 100%;
		height: auto !important;
	}
}

.individual-photo{
	width: 100%;
	max-width: 400px;
}




@media only screen and (max-width: 850px){
	#head-wrapper #head{ 
	.fa-bars{
		display: initial;
	}
	#nav-list-style{
		display: none;
		
	}
	#nav-list-style.mobile-nav{
		display: initial;
		position: fixed;
		right: 0;
		top: 125px;
		background: rgb(241, 238, 238);
		height: 100%;



		#nav-items{
			flex-direction: column;
			padding-left: 20px;
			.nav-item{
				margin: 10px;
				border-bottom: thin solid gray;
			}
		}
	}
}
}


//login page
#login-page{
	margin: 5%;

	h2{
		margin-top: 0;
	}
	p{
		margin: 0;
	}
}